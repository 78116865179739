<template>
  <div class="chat-module-container">
    <div class="chat-header">
      <v-row>
        <v-col lg="10">
          <div class="chat-title mt-4">
            <div class="profile-circle">
              <img src="https://imcoffeefreak.b-cdn.net/SIGNAX%20FAVICON.svg" />
            </div>
            <div class="chat-details ml-2">
              <h1>Team Signa X</h1>
              <p>Contact Us helps you to communication with the Signa X team. You can click on hide button below to hide
                this warning.</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="chat-body">
      <div class="chat-container">
        <div v-for="(message, index) in messages" :key="index">
          <div class="chat-message" v-if="message.user.uid !== user.uid">
            <div style="width: 100%; height: 50px">
              <v-row style="justify-content: flex-start;">
                <v-col lg="1">
                  <v-avatar class="p-10" size="30px" color="white">
                    <img :src="'https://imcoffeefreak.b-cdn.net/SIGNAX%20FAVICON.svg'"
                      style="object-fit: contain; width: 100%; height: 100%" />
                  </v-avatar>
                </v-col>
                <v-col lg="9" style="padding: 0px">
                  <div class="name mt-4 ml-6">
                    Team Signa X
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div v-if="message.message.includes('\n')">
              <p v-for="(mesg, index) in message.message.split('\n')" :key="index"
                style="margin: 0px; font-size: 14px;">
                <span v-if="mesg.includes(':')">
                  <br /><span style="font-weight: bold; color: #ff7f3f;">{{ mesg.split(':')[0] }}:</span><br />
                  <span>{{ mesg.split(':')[1] }}</span>
                </span>
                <span v-else>{{ mesg }}</span>
              </p>
            </div>
            <div v-else>
              {{ message.message }}
            </div>
          </div>
          <div class="chat-message-user" v-else>
            {{ message.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <div>
        <v-row align="center" justify="space-between">
          <v-col lg="8">
            <input placeholder="Type here......" class="input" v-model="chatMessage" />
          </v-col>
          <v-col lg="2">
            <v-btn class="btn" @click="sendMessage()">Send</v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="tools" style="width: 15%; margin-top: 10px">
        <v-row>
          <v-col lg="2">
            <v-icon color="white" size="20" style="cursor: pointer"
              >mdi-paperclip</v-icon
            >
          </v-col>
          <v-col lg="2">
            <v-icon color="white" size="20" style="cursor: pointer"
              >mdi-image</v-icon
            >
          </v-col>
          <v-col lg="2">
            <v-icon color="white" size="20" style="cursor: pointer"
              >mdi-music</v-icon
            >
          </v-col>
          <v-col lg="2">
            <v-icon color="white" size="20" style="cursor: pointer"
              >mdi-file-document-outline</v-icon
            >
          </v-col>
          <v-col lg="2">
            <v-icon color="white" size="20" style="cursor: pointer"
              >mdi-video-3d</v-icon
            >
          </v-col>
        </v-row>
      </div> -->
    </div>
    <!-- <Warning :visible="showWarning" @close="close()" :title="'Information'"
      :message="'Contact Us helps you to communication with the Signa X team. You can click on hide button below to hide this warning.'">
    </Warning> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ADMIN_USER_ID, NOTIFICATION_TYPE_CONTACT_CHAT } from "../../helpers/constants";
import { contactObj } from "../../services/contact-service";
// import Warning from "../Modals/Warning.vue";
import { mailServiceObj } from "../../services/mail-service";
import { notificationObj } from "../../services/notification-service";

export default {
  name: "ContactUs",
  computed: {
    ...mapState({
      user: (state) => state.user,
      hideWarning: (state) => state.hideWarning,
    }),
  },
  data() {
    return {
      messages: [],
      chatMessage: "",
      showWarning: false,
      selectedUserId: "",
    };
  },
  created() {
    this.displayWarning();
    this.getMessages();
  },
  methods: {
    async sendMessage() {
      if (this.user.role === "designer" || this.user.role === 'meta') {
        this.messages.push({
          message: this.chatMessage,
          type: "user",
          user: this.user,
        });
        const jsonData = {
          messages: this.messages,
          userId: this.user.uid,
          sentTime: new Date(),
        };
        await contactObj.sendMessage(jsonData);
        await mailServiceObj.sendMailToAdminForContact(this.user.name, this.chatMessage);
        this.chatMessage = "";
        const notificationData = {
          title: `${this.user.name} just messaged you`,
          message: `${this.chatMessage}`,
          meta: {
            request_id: '',
            chat: false,
          },
        };
        notificationObj.sendNotification(
          notificationData,
          this.user.uid,
          ADMIN_USER_ID,
          NOTIFICATION_TYPE_CONTACT_CHAT
        );
      } else {
        this.messages.push({
          message: this.chatMessage,
          type: "owner",
          user: this.user,
        });
        const jsonData = {
          messages: this.messages,
          userId: selectedUserId,
          sentTime: new Date(),
        };
        await contactObj.adminMessage(jsonData);
        this.chatMessage = "";
      }
    },
    displayWarning() {
      this.showWarning = true;
      this.$store.dispatch('setGlobalPopup', true);
    },
    close() {
      this.showWarning = false;
      this.$store.dispatch('setGlobalPopup', false);
    },
    async getMessages() {
      const chats = [];
      const data = await contactObj.getMessagesFromUserId(this.user.uid);
      if (data.length >= 1) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          for (
            let chatIndex = 0;
            chatIndex < element.messages.length;
            chatIndex++
          ) {
            const chatElement = element.messages[chatIndex];
            chats.push(chatElement);
          }
        }
      }
      this.messages = chats;
    },
  },
  // components: { Warning },
};
</script>
<style scoped>
.chat-module-container {
  width: 100vw;
  height: 100vh;
    background-image: url("../../assets/hue.svg");
  /* background-image: url("../../assets/comm/background.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

h1 {
  font-size: 20px;
  margin-top: 15px;
  margin-left: 15px;
  color: white;
}

.chat-body {
  height: 75vh;
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
}

.chat-footer {
  height: 12vh;
  background: var(--empty-color);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 18% 10px 2%;
}

.input {
  color: white;
  outline: none;
  border: none;
  width: 100%;
  height: 40px;
}

.divider {
  border-color: rgba(255, 255, 255, 0.418) !important;
  margin-top: 10px;
  width: 90% !important;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 100px !important;
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 18px !important;
}

.chat-message {
  margin-left: 2%;
  width: auto;
  max-width: 50%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #00ffbf;
  color: white;
  font-family: "Archivo", sans-serif !important;
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
  background: rgba(196, 196, 196, 0.25);
}

.chat-message-user {
  margin-left: auto;
  width: auto;
  max-width: 40%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #fbb142;
  color: white;
  font-family: "Archivo", sans-serif !important;
  font-size: 14px;
  line-height: 25px;
  margin-right: 20%;
  background: rgba(196, 196, 196, 0.25);
  /* display: inline-block; */
}

.submit-btn {
  margin-top: 15px;
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 40%;
  margin-top: 10px;
  color: white;
}

.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: red;
}

.profile>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.name {
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.chat-header {
  height: 13vh;
  background: var(--nav-bar-color);
  display: flex;
  justify-content: flex-start;
}

.chat-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
}

.profile-circle>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chat-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.chat-details>h1 {
  margin: 0px;
  font-size: 22px;
  font-weight: 800;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.chat-details>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}
</style>
